import ToastificationContent from "@core/components/toastification/ToastificationContent";

export default {
    data() {
        return {
            fileErrors: [],
            fileErrorMessages: {
                sizeError: 'Files should not exceed 10MB in size!',
                typeError: 'Only image files are allowed!'
            },
            allowedMimes: ['image/jpeg', 'image/jpg', 'image/png'],
            maxSize: 10, //mb,
        }
    },
    computed: {
        hasFileError() {
            return this.fileErrors.length > 0
        }
    },
    methods: {
        validateFile(file) {
            if (file.size > this.maxSize * 1024 ** 2) {
                this.fileErrors.push(this.fileErrorMessages.sizeError)
            }

            if (!this.allowedMimes.includes(file.type)) {
                this.fileErrors.push(this.fileErrorMessages.typeError)
            }

            return this.hasFileError
        },
        showFileToast() {
            this.fileErrors.forEach(item => {
                this.$toast({
                    component: ToastificationContent,
                    props: {
                        title: item,
                        icon: "XCircleIcon",
                        variant: "danger",
                    },
                });
            })
        },
        dragover(event) {
            event.preventDefault();
            // Add some visual fluff to show the user can drop its files
            if (!event.currentTarget.classList.contains('drag-file')) {
                event.currentTarget.classList.add('drag-file');
            }
        },
        dragleave(event) {
            // Clean up
            event.currentTarget.classList.remove('drag-file');
        },
        drop(event) {
            // remove hover class
            event.currentTarget.classList.remove('drag-file');
            // if has file return
            if (this.file) return
            // reset file errors
            this.fileErrors = []

            event.preventDefault();
            const file = event.dataTransfer.files[0]
            if (file) {
                this.handleFile(file)
            }
        }
    }
}